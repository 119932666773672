import type React from 'react'
import type { ReactNode } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Reset } from 'styled-reset'

import Media from '../../utils/Media'
import { Footer } from './Footer'
import { Header } from './Header'

type Props = {
  mode?: 'default' | 'blank'
  children: ReactNode
}

const Layout: React.FC<Props> = ({ mode = 'default', children }: Props) => (
  <div>
    <Reset />
    <GlobalStyle />
    {mode !== 'blank' && <Header />}
    <Main>{children}</Main>
    {mode !== 'blank' && <Footer />}
  </div>
)

const Main = styled.div`
  ${Media.desktop} {
    margin-top: 90px;
  }

  ${Media.mobileTablet} {
    margin-top: 80px;
  }
`

const GlobalStyle = createGlobalStyle`
  html {
    font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', HiraginoCustom, 'Hiragino Kaku Gothic ProN', YuGothic, 'Yu Gothic Medium', Meiryo, sans-serif;
  }
`

export { Layout }
