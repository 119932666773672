import styled, { css } from 'styled-components'

type Size = 'small' | 'middle' | 'large' // default is large

const contanierWidth = (size: Size) => {
  switch (size) {
    case 'small':
      return 784
    case 'middle':
      return 1000
    case 'large':
      return 1200
    default:
      return '' as never
  }
}

type ContainerProps = {
  size?: Size
}

const ContainerStyle = css<ContainerProps>`
  max-width: ${(props) => contanierWidth(props.size || 'large')}px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
`

const Container = styled.div<{ size?: Size }>`
  ${ContainerStyle};
`

export { Container, ContainerStyle }
export type { ContainerProps }
