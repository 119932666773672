import useMedia from 'use-media'
import type { MediaQueryObject } from 'use-media/lib/types'

export const DESKTOP_WIDTH = 992
export const MOBILE_WIDTH = 576

const MEDIA_RANGES = {
  desktop: { minWidth: DESKTOP_WIDTH + 1 },
  tablet: { minWidth: MOBILE_WIDTH + 1, maxWidth: DESKTOP_WIDTH },
  mobile: { maxWidth: MOBILE_WIDTH },
}

export default {
  desktop: `@media screen and (min-width: ${DESKTOP_WIDTH + 1}px)`,
  tablet: `@media screen and (min-width: ${
    MOBILE_WIDTH + 1
  }px) and (max-width: ${DESKTOP_WIDTH}px)`,
  mobile: `@media screen and (max-width: ${MOBILE_WIDTH}px)`,
  mobileTablet: `@media screen and (max-width: ${DESKTOP_WIDTH}px)`,
  tabletDesktop: `@media screen and (min-width: ${MOBILE_WIDTH + 1}px)`,
}

type MediaType = 'desktop' | 'tablet' | 'mobile'

export const useMediaQuery = (media: MediaType) => {
  let query!: MediaQueryObject
  switch (media) {
    case 'desktop':
      query = MEDIA_RANGES.desktop
      break
    case 'tablet':
      query = MEDIA_RANGES.tablet
      break
    case 'mobile':
      query = MEDIA_RANGES.mobile
      break
    default:
  }
  return useMedia(query)
}
