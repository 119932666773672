import type React from 'react'
import styled from 'styled-components'

import theme from '../../../theme'
import Media from '../../../utils/Media'
import { Container, type ContainerProps, ContainerStyle } from '../Container'
import { ScrollToTop } from '../ScrollToTop'
import { FooterMenu } from './FooterMenu'

const Footer: React.FC = () => (
  <footer>
    <Main>
      <StyledScrollToTop />
      <StyledContainer>
        <FooterMenu />
      </StyledContainer>
      <CopyrightStyle>
        <Copyright>RYDE, Inc. All Rights Reserved.</Copyright>
      </CopyrightStyle>
    </Main>
  </footer>
)

const StyledContainer = styled(Container)`
  ${Media.desktop} {
    height: 156px;
  }
`

const StyledScrollToTop = styled(ScrollToTop)`
  position: absolute;
  z-index: 100;
  top: -25px;
  right: 30px;

  ${Media.desktop} {
    right: 160px;
  }
`

const Main = styled.section`
  position: relative;
  padding-top: 40px;
  background: ${theme.colors.brand};
  color: ${theme.colors.white};

  ${Media.desktop} {
    padding-top: 86px;
    padding-bottom: 30px;
  }
`

const CopyrightStyle = styled.div<ContainerProps>`
  ${ContainerStyle};

  ${Media.mobileTablet} {
    padding: 0;
  }

  ${Media.desktop} {
    margin-top: 130px;
  }
`

const Copyright = styled.p`
  color: ${theme.colors.textWhitePrimary};
  font-size: 14px;

  ${Media.desktop} {
    display: inline-block;
    width: 100%;
    height: 42px;
    padding: 0;
    line-height: 42px;
    text-align: right;
    vertical-align: middle;
  }

  ${Media.mobileTablet} {
    height: 64px;
    margin-top: 60px;
    border-top: solid 1px ${theme.colors.borderWhite};
    line-height: 64px;
    text-align: center;
  }
`

export { Footer }
